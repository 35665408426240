<template>
<el-dialog v-model="visible" title="打印预览" :before-close="close" custom-class="text-report-print-preview-dialog">
  <div class="trppd-body">
    <div class="trppd-tabs row-center-center">
      <div class="trppdt-item" @click="active=4" :class="active==4?'is-actived':''">A4打印</div>
      <div class="trppdt-item" @click="active=5" :class="active==5?'is-actived':''">A5打印</div>
    </div>
    <div class="trppd-priview" v-loading="loading" v-if="iframeShow">
      <iframe id="iframe_4" ref="iframe_4" v-if="active==4" src="../printPreview/A4.html" style="width: 100%;height: calc(100% - 8px);" frameborder="0" />
      <iframe id="iframe_5" ref="iframe_5" v-if="active==5" src="../printPreview/A5.html" style="width: 100%;height: calc(100% - 8px);" frameborder="0" />
    </div>
    <div class="trppd-priview column-center-center" v-else>
      暂无数据
    </div>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-checkbox v-model="printClinicName" @change="handleChecked">是否打印<span>诊所名称</span></el-checkbox>
      <div class="row-center-center">
        <el-button type="primary" round @click="goPrint" :disabled="!iframeShow">打 印</el-button>
      </div>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 打印预览
import { onMounted, reactive, toRefs } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { getOrderDetailById } from "api/apis.js";
export default {
  props: {
    printData: { type: Object }
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      active: 4,
      printClinicName: false,
      loading: false,
      iframeShow: false
    })

    onMounted(() => {
      getDetailData();
    })

    // 获取详细信息
    const getDetailData = () => {
      state.loading = true;
      state.iframeShow = false;
      getOrderDetailById({ 
        orderId: prop.printData.orderId,
        deviceTypeCode: prop.printData.deviceTypeCode
      }).then(response => {
        if (response.code == 200) {
          // response.data.cvwbc.wbcHist = [];
          // response.data.cvwbc.rbcHist = [];
          // response.data.cvwbc.pltHist = [];
          let data = {
            info: response.data.baseInfo,
            result: response.data
          };
          data.info.deviceTypeCode = prop.printData.deviceTypeCode;
          sessionStorage.setItem("printData", JSON.stringify(data));
          state.iframeShow = true;
        } else {
          sessionStorage.setItem("printData", JSON.stringify({}));
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 打印  区分A4/A5
    const goPrint = () => {
      let iframe;
      // 获取 iframe 中的 HTML
      if (state.active == 4) {
        iframe = document.getElementById("iframe_4");
      } else {
        iframe = document.getElementById("iframe_5");
      }

      // 判断是否打印 诊所名称，并设置
      if (state.printClinicName) {
        // 需要诊所名称 设置 iframe 中的 print-clinic-name: display=block;
        // 调用 iframe 中的方法
        iframe.contentWindow.changeClinicName(true);
      } else {
        // 不要诊所名称，
        // 调用 iframe 中的方法 
        iframe.contentWindow.changeClinicName(false);
      }
      // 打印
      iframe.contentWindow.print();
    };

    // checkbox 值 变化事件
    const handleChecked = event => {
      if (event) {
        ElMessageBox.confirm("选择后会在患者报告中显示诊所名称！", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false
        }).then(() => {}).catch(() => {
          state.printClinicName = false;
        });
      }
    };

    const close = () => {
      ctx.emit("close");
    };

    return {
      ...toRefs(state),
      close,
      goPrint,
      handleChecked,
      getDetailData
    }
  }
}
</script>

<style lang="scss">
.text-report-print-preview-dialog {
  width: 800px;
  height: calc(100% - 25vh);

  .el-dialog__header {
    font-weight: bold;
  }

  .el-dialog__body {
    padding: 10px 40px;
    height: calc(100% - 140px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .trppd-body {
      height: 100%;
      width: 80%;

      .trppd-tabs {
        padding: 4px 8px;
        background-color: #f8f8f8;

        .trppdt-item {
          padding: 6px 0;
          cursor: pointer;
          text-align: center;
          width: calc(100% / 2);

          &.is-actived {
            color: #63b4ff;
            font-weight: bold;
            background-color: #ffffff;
          }
        }
      }

      .trppd-priview {
        margin-top: 4px;
        padding: 4px 8px;
        border-radius: 8px;
        background-color: #ddeeff;
        height: calc(100% - 45px);

        iframe {
          border-radius: 10px;
        }
      }
    }
  }

  .el-dialog__footer {
    padding: 10px 40px;
  }

  .dialog-footer {
    width: 80%;
    display: flex;
    flex-direction: column;

    .el-checkbox__label {
      letter-spacing: 2px;

      span {
        color: #5ab4ff;
      }
    }

    .el-button {
      width: 140px;
    }
  }
}
</style>
